import React from "react";

const SvgComponent = ({ svgRef, title, ...props }) => (
  <svg
    width="0.82828282828em"
    height="1em"
    viewBox="0 0 158 191"
    ref={svgRef}
    {...props}
  >
    <title>{title}</title>
    <defs>
      <path id="prefix__a" d="M0 0h164v198H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        fill="#F6DF74"
        d="M34.06 132.211h22.317c2.008 0 2.733-1.818 2.733-1.818L78.24 96.3h1.366l18.673 33.64s1.068 2.272 2.733 2.272h23.683c.882 0 .912-1.314.456-2.272-.457-.959-45.089-79.553-45.089-79.553s-.456-.795-.912-.91c-.454-.113-.955.185-1.366.91l-45.09 79.553s-.767 2.272 1.367 2.272zM79.996 0c2.738 1.784 5.42 3.668 8.227 5.337A9861.399 9861.399 0 0 0 127.71 28.65c4.035 2.372 8.151 4.61 12.192 6.973 1.57.918 3.005 2.063 4.558 3.016 2.352 1.443 4.758 2.802 7.133 4.21 1.778 1.054 3.58 2.075 5.292 3.23.52.352 1.1 1.097 1.104 1.665.04 5.672-.042 11.343-.07 17.015-.022 4.277-.023 8.554-.019 12.83.006 7.224.068 14.45.02 21.673-.045 7.273-.273 14.547-.277 21.82-.003 5.903.23 11.805.317 17.709.042 2.892.063 5.789-.063 8.677-.036.827-.46 1.75-.973 2.428-2.59 3.417-5.184 6.836-7.943 10.116-2.252 2.679-4.737 5.163-7.118 7.734-.227.244-.422.538-.693.72-3.768 2.518-6.8 6.015-11.082 7.893-2.37 1.04-4.349 2.934-6.67 4.118-7.93 4.045-16.106 7.391-24.98 8.909-7.237 1.238-14.507 1.825-21.816 1.545-5.333-.205-10.714-.56-15.951-1.513-4.612-.838-9.172-2.303-13.543-4.026-5.17-2.036-10.171-4.535-15.129-7.06-2.145-1.094-4.073-2.666-5.97-4.184-3.697-2.958-7.554-5.78-10.888-9.114C9.606 159.5 4.59 153.45.918 146.498c-.91-1.72-.739-4.09-.78-6.167-.07-3.38.11-6.764.148-10.147.012-1.056-.146-2.114-.138-3.17.06-7.851.21-15.702.197-23.553C.331 94.591.148 85.72.094 76.848c-.041-6.511.014-13.022.01-19.533-.004-2.856.005-5.712-.1-8.564-.051-1.382.36-2.305 1.61-2.992a263.806 263.806 0 0 0 8.944-5.139c5.346-3.217 10.623-6.551 15.99-9.735 4.938-2.928 9.985-5.67 14.926-8.594 2.874-1.7 5.594-3.657 8.454-5.385 5.061-3.059 10.183-6.019 15.267-9.042C69.578 5.26 73.943 2.624 78.317 0h1.679z"
        mask="url(#prefix__b)"
      />
    </g>
  </svg>
);

const InternshipsIcon = React.forwardRef((props, ref) => (
  <SvgComponent svgRef={ref} {...props} />
));
export default InternshipsIcon;
